import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from 'framer-motion';
import { ButtonForm } from '../components/UI/buttons/form';

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ContactForm = () => {

    const [state, setState] = React.useState({})
    const [validate, setValidate] = React.useState(false)

    const handleChange = e => setState({ ...state, [e.target.name]: e.target.value })

    // CSS styling Tailwind
    const LabelStyle = "mb-5 font-poppins-medium w-full flex flex-col items-center justify-center"
    const TitleStyle = "w-full text-md font-radiance text-purple-800 tracking-normal mb-5"
    const InputStyle = "p-2 px-4 shadow-sm label-bg outline-none font-radiance border-0 rounded-md text-2xl md:text-lg text-purple-700 transition ease-out duration-700 w-full"
    const ErrorMessage = "w-full py-2 tracking-normal text-red-600 font-poppins-medium text-xs h-5"
    const InputMessageStyle = "p-2 px-4 outline-none label-bg rounded-md font-radiance resize-none text-lg text-purple-700 w-full h-32"
    const SubmitButtonStyle = "mb-8 focus:outline-none outline-none border-grey border-b-4 rounded-full text-xs  text-white uppercase font-Gilroy_extrabold tracking-widest py-2 px-8 bg-btn-green"

    const { register, errors, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault()
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                // 'form-name': form.getAttribute('name'),
                'form-name': 'contact',
                ...state,
            }),
        }).then(
            setValidate(true)
        )

    }

    const errorAnim = {
        initial: {
            opacity: 0,
            x: -10
        },
        enter: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 10,
                mass: 1.5,
                stiffness: 150,
            },
        },
        exit: {
            opacity: 0,
            x: 0,
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 10,
                mass: 1.5,
                stiffness: 150,
            },
        },
    };

    const formAnim = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
    };

    return (
        <>
            <section className="w-full relative flex items-center justify-center flex-col">
                {validate &&
                    <motion.div animate={{ opacity: 1 }} className="opacity-0 absolute w-full h-full">
                        Merci !
        </motion.div>
                }
                <form
                    name="contact"
                    className="w-full"
                    method="POST"
                    data-netlify="true"
                    onSubmit={handleSubmit(onSubmit)}
                    action="/"
                    netlify-honeypot="bot-field"
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <p className="hidden">
                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <AnimatePresence>
                        {validate === false &&
                            <motion.div
                                initial="initial"
                                animate="enter"
                                exit="exit"
                                variants={formAnim}
                                className="flex items-center justify-center flex-col"
                            >
                                <motion.label className={LabelStyle}>
                                        <p className={TitleStyle}>Nom</p>
                                        <motion.input className={InputStyle} type="text" name="nameInput" onChange={handleChange} ref={register({ required: true })} />
                                        <AnimatePresence>
                                            <div className="h-5">
                                                {errors.nameInput &&
                                                    <motion.p
                                                        initial="initial"
                                                        animate="enter"
                                                        exit="exit"
                                                        variants={errorAnim}
                                                        className={ErrorMessage} >
                                                        ✘ Champ requis
                                </motion.p>
                                                }
                                            </div>
                                        </AnimatePresence>
                                </motion.label>
                                <label className={LabelStyle}>
                                        <p className={TitleStyle}>Email</p>
                                        <input className={InputStyle} type="email" name="email" onChange={handleChange} ref={register({ required: true })} />
                                        <AnimatePresence>
                                            <div className="h-5">
                                                {errors.email &&
                                                    <motion.p
                                                        initial="initial"
                                                        animate="enter"
                                                        exit="exit"
                                                        variants={errorAnim}
                                                        className={ErrorMessage} >
                                                        ✘ Champ requis
                                </motion.p>
                                                }
                                                                                    </div>
                                        </AnimatePresence>
                                </label>
                                <label className={LabelStyle}>
                                        <p className={TitleStyle}>Message</p>
                                        <textarea className={InputMessageStyle} name="message" onChange={handleChange} maxLength="350" ref={register({ required: true })}></textarea>
                                        <AnimatePresence>
                                            <div className="h-5">
                                                {errors.message &&
                                                    <motion.p
                                                        initial="initial"
                                                        animate="enter"
                                                        exit="exit"
                                                        variants={errorAnim}
                                                        className={ErrorMessage} >
                                                        ✘ Champ requis
                                </motion.p>
                                                }
                                            </div>
                                        </AnimatePresence>
                                </label>
                                <ButtonForm icon="send" color="pink" >Send</ButtonForm>
                            </motion.div>
                        }
                    </AnimatePresence>
                </form>
            </section>
        </>
    )
}

export default ContactForm;
